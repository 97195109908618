import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = ` ${BaseURL}/Mechs/HA/Barbarossa-MKII`;

// BODY
const bodyBarbarossaImage = `${imagePath}/Chassis.png`;
const legsStandardImage = `${imagePath}/Legs - Standard.png`;
const legsDigitigradeImage = `${imagePath}/Legs - Digitigrade.png`;

// HEADS
const head8BallImage = `${imagePath}/heads/Head - 8Ball.png`;
const headBlackspotImage = `${imagePath}/heads/Head - Blackspot.png`;
const headCalibanImage = `${imagePath}/heads/Head - Caliban.png`;
const headCheddahImage = `${imagePath}/heads/Head - Cheddah.png`;
const headChimeraImage = `${imagePath}/heads/Head - Chimera.png`;
const headCyclopsImage = `${imagePath}/heads/Head - Cyclops.png`;
const headDomImage = `${imagePath}/heads/Head - Dom.png`;
const headDungamImage = `${imagePath}/heads/Head - Dungam.png`;
const headFrundsbergImage = `${imagePath}/heads/Head - Frundsberg.png`;
const headFTNelsonImage = `${imagePath}/heads/Head - FT Nelson.png`;
const headHeadCannonImage = `${imagePath}/heads/Head - Head Cannon.png`;
const headHeavyArmorImage = `${imagePath}/heads/Head - Heavy Armor.png`;
const headHornedImage = `${imagePath}/heads/Head - Horned.png`;
const headHornedHairImage = `${imagePath}/heads/Head - Horned Hair.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headKerfImage = `${imagePath}/heads/Head - Kerf.png`;
const headLaborerImage = `${imagePath}/heads/Head - Laborer.png`;
const headLycan2Image = `${imagePath}/heads/Head - Lycan 2.png`;
const headMagImage = `${imagePath}/heads/Head - Mag.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headMecha1Image = `${imagePath}/heads/Head - Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Head - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Head - Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/Head - Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/Head - Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/Head - Mecha 8.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Head - Natural Law Hair.png`;
const headNemesisImage = `${imagePath}/heads/Head - Nemesis.png`;
const headRainTransformedImage = `${imagePath}/heads/Head - Rain Transformed.png`;
const headRainTransformedHairImage = `${imagePath}/heads/Head - Rain Transformed Hair.png`;
const headSkullImage = `${imagePath}/heads/Head - Skull.png`;
const headTokugawaImage = `${imagePath}/heads/Head - Tokugawa.png`;
const headViceroyImage = `${imagePath}/heads/Head - Viceroy.png`;
const headVisionaryImage = `${imagePath}/heads/Head - Visionary.png`;

// ARMS
// LEFT
const leftArmBastardSwordImage = `${imagePath}/arms/Left Arm - Bastard Sword.png`;
const leftArmDD288Image = `${imagePath}/arms/Left Arm - DD288.png`;
const leftArmDualGatlingImage = `${imagePath}/arms/Left Arm - Dual Gatling.png`;
const leftArmFistImage = `${imagePath}/arms/Left Arm - Fist.png`;
const leftArmHMGImage = `${imagePath}/arms/Left Arm - HMG.png`;
const leftArmPolearmImage = `${imagePath}/arms/Left Arm - Polearm.png`;
const leftArmRailgunImage = `${imagePath}/arms/Left Arm - Railgun.png`;

// RIGHT
const rightArmDD288Image = `${imagePath}/arms/Right Arm - DD288.png`;
const rightArmFistImage = `${imagePath}/arms/Right Arm - Fist.png`;
const rightArmFist2Image = `${imagePath}/arms/Right Arm - Fist 2.png`;
const rightArmHeavyCannonsImage = `${imagePath}/arms/Right Arm - Heavy Cannons.png`;
const rightArmHMGImage = `${imagePath}/arms/Right Arm - HMG.png`;
const rightArmLandsknechtPaviseImage = `${imagePath}/arms/Right Arm - Landsknecht Pavise.png`;
const rightArmRailgunImage = `${imagePath}/arms/Right Arm - Railgun.png`;
const rightArmTitanRotaryCannonImage = `${imagePath}/arms/Right Arm - Titan Rotary Cannon.png`;
const rightArmCombatDrillImage = `${imagePath}/arms/Right Arm - Combat Drill.png`;

// ACCESSORY
const accBannersImage = `${imagePath}/accessory/Accessory - Banners.png`;
const accFrundsbergImage = `${imagePath}/accessory/Accessories - Frundsberg.png`;
const accTabardImage = `${imagePath}/accessory/Accessory - Tabard.png`;
const accTabardUnderImage = `${imagePath}/accessory/Under - Tabard.png`;
const accTabardBannersImage = `${imagePath}/accessory/Accessory - Tabard Banners.png`;
const accLeftHMGImage = `${imagePath}/accessory/Left Shoulder - HMG.png`;
const accLeftRailgunImage = `${imagePath}/accessory/Left Shoulder - Railgun.png`;
const accLeftSiegeCannonImage = `${imagePath}/accessory/Left Shoulder - Siege Cannon.png`;
const accRightHMGImage = `${imagePath}/accessory/Right Shoulder - HMG.png`;
const accRightRailgunImage = `${imagePath}/accessory/Right Shoulder - Railgun.png`;
const accRightSiegeCannonImage = `${imagePath}/accessory/Right Shoulder - Siege Cannon.png`;
const accLeftFlightUnitImage = `${imagePath}/accessory/Left Shoulder - Flight Unit.png`;
const accRightFlightUnitImage = `${imagePath}/accessory/Right Shoulder - Flight Unit.png`;

// GLOWS
const glowHeadCannonImage = `${imagePath}/glows/Glow - Head Cannon.png`;
const glowOwOImage = `${imagePath}/glows/Glow - OwO.png`;
const glowOwOAmgryImage = `${imagePath}/glows/Glow - OwO Amgry.png`;
const glowPolearmImage = `${imagePath}/glows/Glow - Left Arm Polearm.png`;
const glowLeftRailgunImage = `${imagePath}/glows/Glow - Left Railgun.png`;
const glowRightRailgunImage = `${imagePath}/glows/Glow - Right Railgun.png`;
const glowRailgunDigitigradeImage = `${imagePath}/glows/Glow - Left Railgun Digitigrade.png`;
const glowRailgunStandardImage = `${imagePath}/glows/Glow - Left Railgun Standard.png`;
const glowRailgunArmImage = `${imagePath}/glows/Glow - Right Railgun Arm.png`;
const glowFlightUnitImage = `${imagePath}/glows/Glow - Flight Unit.png`;

const previewImage = `${imagePath}/preview.png`;

const coreImage = `${imagePath}/Barbarossa_Mk2_by_gummies139.png`;
const core2Image = `${imagePath}/Frundsberg-corepaintjob-Fliren.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "-6px",
    right: "-23px",
};

export default {
    previewImg: previewImage,
    isMegadeusPlus: true,
    rearMountsAreChassis: true,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Gummies139",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core2Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Fliren",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "15",
        "rearMountValue": "NONE",
        "weaponValue": "3",
        "secondaryWeaponValue": "1",
        "accessory1Value": "3",
        "accessory2Value": "9",
        "accessory3Value": "21",
        "accessory4Value": "30",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "8 BALL",
            src: head8BallImage,
        },
        {
            value: 1,
            label: "BLACKSPOT",
            src: headBlackspotImage,
        },
        {
            value: 2,
            label: "CALIBAN",
            src: headCalibanImage,
        },
        {
            value: 3,
            label: "CHEDDAH",
            src: headCheddahImage,
        },
        {
            value: 4,
            label: "CHIMERA",
            src: headChimeraImage,
        },
        {
            value: 5,
            label: "CYCLOPS",
            src: headCyclopsImage,
        },
        {
            value: 6,
            label: "DOM",
            src: headDomImage,
        },
        {
            value: 7,
            label: "DUNGAM",
            src: headDungamImage,
        },
        {
            value: 8,
            label: "FRUNDSBERG",
            src: headFrundsbergImage,
        },
        {
            value: 9,
            label: "FT NELSON",
            src: headFTNelsonImage,
        },
        {
            value: 10,
            label: "HEAD CANNON",
            src: headHeadCannonImage,
        },
        {
            value: 11,
            label: "HEAVY ARMOR",
            src: headHeavyArmorImage,
        },
        {
            value: 12,
            label: "HORNED",
            src: headHornedImage,
        },
        {
            value: 13,
            label: "HORNED HAIR",
            src: headHornedHairImage,
        },
        {
            value: 14,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 15,
            label: "KERF",
            src: headKerfImage,
        },
        {
            value: 16,
            label: "LABORER",
            src: headLaborerImage,
        },
        {
            value: 17,
            label: "LYCAN 2",
            src: headLycan2Image,
        },
        {
            value: 18,
            label: "MAG",
            src: headMagImage,
        },
        {
            value: 19,
            label: "MALICE",
            src: headMaliceImage,
        },
        {
            value: 20,
            label: "MECHA 1",
            src: headMecha1Image,
        },
        {
            value: 21,
            label: "MECHA 2",
            src: headMecha2Image,
        },
        {
            value: 22,
            label: "MECHA 3",
            src: headMecha3Image,
        },
        {
            value: 23,
            label: "MECHA 4",
            src: headMecha4Image,
        },
        {
            value: 24,
            label: "MECHA 5",
            src: headMecha5Image,
        },
        {
            value: 25,
            label: "MECHA 6",
            src: headMecha6Image,
        },
        {
            value: 26,
            label: "MECHA 7",
            src: headMecha7Image,
        },
        {
            value: 27,
            label: "MECHA 8",
            src: headMecha8Image,
        },
        {
            value: 28,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 29,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
        },
        {
            value: 30,
            label: "NEMESIS",
            src: headNemesisImage,
        },
        {
            value: 31,
            label: "RAIN TRANSFORMED",
            src: headRainTransformedImage,
        },
        {
            value: 32,
            label: "RAIN TRANSFORMED HAIR",
            src: headRainTransformedHairImage,
        },
        {
            value: 33,
            label: "SKULL",
            src: headSkullImage,
        },
        {
            value: 34,
            label: "TOKUGAWA",
            src: headTokugawaImage,
        },
        {
            value: 35,
            label: "VICEROY",
            src: headViceroyImage,
        },
        {
            value: 36,
            label: "VISIONARY",
            src: headVisionaryImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "STANDARD LEGS",
            src: bodyBarbarossaImage,
            backSrc: legsStandardImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 1,
            label: "DIGITIGRADE LEGS",
            src: bodyBarbarossaImage,
            backSrc: legsDigitigradeImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "BASTARD SWORD",
                backmostSrc: leftArmBastardSwordImage,
            },
            {
                value: 1,
                label: "DD288",
                backmostSrc: leftArmDD288Image,
            },
            {
                value: 2,
                label: "DUAL GATLING",
                backmostSrc: leftArmDualGatlingImage,
            },
            {
                value: 3,
                label: "FIST",
                backmostSrc: leftArmFistImage,
            },
            {
                value: 4,
                label: "HMG",
                backmostSrc: leftArmHMGImage,
            },
            {
                value: 5,
                label: "POLEARM",
                backmostSrc: leftArmPolearmImage,
            },
            {
                value: 6,
                label: "RAILGUN",
                backmostSrc: leftArmRailgunImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "DD288",
            src: rightArmDD288Image,
        },
        {
            value: 1,
            label: "FIST",
            src: rightArmFistImage,
        },
        {
            value: 2,
            label: "FIST 2",
            src: rightArmFist2Image,
        },
        {
            value: 3,
            label: "HEAVY CANNONS",
            src: rightArmHeavyCannonsImage,
        },
        {
            value: 4,
            label: "HMG",
            src: rightArmHMGImage,
        },
        {
            value: 5,
            label: "LANDSKNECHT PAVISE",
            src: rightArmLandsknechtPaviseImage,
        },
        {
            value: 6,
            label: "RAILGUN",
            src: rightArmRailgunImage,
        },
        {
            value: 7,
            label: "TITAN ROTARY CANNON",
            src: rightArmTitanRotaryCannonImage,
        },
        {
            value: 8,
            label: "COMBAT DRILL",
            src: rightArmCombatDrillImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "BANNERS",
            src: accBannersImage,
        },
        {
            value: 1,
            label: "FRUNDSBERG",
            src: accFrundsbergImage,
        },
        {
            value: 2,
            label: "TABARD",
            src: accTabardImage,
            backmostSrc: accTabardUnderImage,
        },
        {
            value: 3,
            label: "TABARD + BANNERS",
            src: accTabardBannersImage,
            backmostSrc: accTabardUnderImage,
        },
        {
            value: 4,
            label: "SHOULDER - HMG + NONE",
            backSrc: accLeftHMGImage,
        },
        {
            value: 5,
            label: "SHOULDER - NONE + HMG",
            frontSrc: accRightHMGImage,
        },
        {
            value: 6,
            label: "SHOULDER - HMG + HMG",
            backSrc: accLeftHMGImage,
            frontSrc: accRightHMGImage,
        },
        {
            value: 7,
            label: "SHOULDER - RAILGUN + NONE",
            backSrc: accLeftRailgunImage,
        },
        {
            value: 8,
            label: "SHOULDER - NONE + RAILGUN",
            frontSrc: accRightRailgunImage,
        },
        {
            value: 9,
            label: "SHOULDER - RAILGUN + RAILGUN",
            backSrc: accLeftRailgunImage,
            frontSrc: accRightRailgunImage,
        },
        {
            value: 10,
            label: "SHOULDER - SIEGE CANNON + NONE",
            backSrc: accLeftSiegeCannonImage,
        },
        {
            value: 11,
            label: "SHOULDER - NONE + SIEGE CANNON",
            frontSrc: accRightSiegeCannonImage,
        },
        {
            value: 12,
            label: "SHOULDER - SIEGE CANNON + SIEGE CANNON",
            backSrc: accLeftSiegeCannonImage,
            frontSrc: accRightSiegeCannonImage,
        },
        {
            value: 13,
            label: "SHOULDER - RAILGUN + HMG",
            backSrc: accLeftRailgunImage,
            frontSrc: accRightHMGImage,
        },
        {
            value: 14,
            label: "SHOULDER - RAILGUN + SIEGE CANNON",
            backSrc: accLeftRailgunImage,
            frontSrc: accRightSiegeCannonImage,
        },
        {
            value: 15,
            label: "SHOULDER - SIEGE CANNON + RAILGUN",
            backSrc: accLeftSiegeCannonImage,
            frontSrc: accRightRailgunImage,
        },
        {
            value: 16,
            label: "SHOULDER - HMG + RAILGUN",
            backSrc: accLeftHMGImage,
            frontSrc: accRightRailgunImage,
        },
        {
            value: 17,
            label: "SHOULDER - HMG + SIEGE CANNON",
            backSrc: accLeftHMGImage,
            frontSrc: accRightSiegeCannonImage,
        },
        {
            value: 18,
            label: "SHOULDER - SIEGE CANNON + HMG",
            backSrc: accLeftSiegeCannonImage,
            frontSrc: accRightHMGImage,
        },
        {
            value: 19,
            label: "SHOULDER - FLIGHT UNITS",
            backSrc: accLeftFlightUnitImage,
            src: accRightFlightUnitImage,
        },
        {
            value: 20,
            label: "GLOW - HEAD CANNON",
            frontSrc: glowHeadCannonImage,
        },
        {
            value: 21,
            label: "GLOW - OwO",
            frontSrc: glowOwOImage,
        },
        {
            value: 22,
            label: "GLOW - OwO AMGRY",
            frontSrc: glowOwOAmgryImage,
        },
        {
            value: 23,
            label: "GLOW - POLEARM",
            frontSrc: glowPolearmImage,
        },
        {
            value: 24,
            label: "GLOW - LEFT RAILGUN DIGITIGRADE - ARMS",
            frontSrc: glowRailgunDigitigradeImage,
        },
        {
            value: 25,
            label: "GLOW - LEFT RAILGUN - ARMS",
            frontSrc: glowRailgunStandardImage,
        },
        {
            value: 26,
            label: "GLOW - RIGHT RAILGUN - ARMS",
            foremostSrc: glowRailgunArmImage,
        },
        {
            value: 27,
            label: "GLOW - DUAL RAILGUNS - ARMS",
            frontSrc: glowRailgunStandardImage,
            foremostSrc: glowRailgunArmImage,
        },
        {
            value: 28,
            label: "GLOW - LEFT RAILGUN - SHOULDER",
            frontSrc: glowLeftRailgunImage,
        },
        {
            value: 29,
            label: "GLOW - RIGHT RAILGUN - SHOULDER",
            foremostSrc: glowRightRailgunImage,
        },
        {
            value: 30,
            label: "GLOW - DUAL RAILGUNS - SHOULDER",
            frontSrc: glowLeftRailgunImage,
            foremostSrc: glowRightRailgunImage,
        },
        {
            value: 31,
            label: "GLOW - FLIGHT UNITS",
            foremostSrc: glowFlightUnitImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "ACCESSORIES / GLOWS",
            value: "ACCESSORIES",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "ARM L",
            value: "weapon",
        },
        {
            label: "ARM R",
            value: "secondaryWeapon",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "GLOW 1",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "EMPTY",
        "weapon": "ARM L",
        "secondaryWeapon": "ARM R",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "GLOW 1",
        "accessory4": "GLOW 2",
    },
};
