import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/IPS/Taraxacum`;

// BODY
const bodyImage = `${imagePath}/Chassis - Taraxacum.png`;
const body2Image = `${imagePath}/Chassis 1 - Shramp.png`;

// TOP
const topAntennaeImage = `${imagePath}/top/Top Mount - Antennae.png`;
const topAWACSImage = `${imagePath}/top/Top Mount - AWACS.png`;
const topBallTurretImage = `${imagePath}/top/Top Mount - Ball Turret.png`;
const topCargoBracesImage = `${imagePath}/top/Top Mount - Cargo Braces.png`;
const topChassisAdditionImage = `${imagePath}/top/Top Mount - Chassis Addition.png`;
const topMissilesImage = `${imagePath}/top/Top Mount - Missiles.png`;

// UNDER
const lowerBallTurretImage = `${imagePath}/lower/Lower - Ball Turret.png`;
const lowerBarLandingGearImage = `${imagePath}/lower/Lower - Bar Landing Gear.png`;
const lowerBipedDownImage = `${imagePath}/lower/Lower - Biped Down.png`;
const lowerBipedRetractedImage = `${imagePath}/lower/Lower - Biped Retracted.png`;
const lowerCargoCraneImage = `${imagePath}/lower/Lower - Cargo Crane.png`;
const lowerQuadLegsImage = `${imagePath}/lower/Lower - Quad Legs.png`;

// ARMS
// LEFT
const armsLeftAssaultRiflesImage = `${imagePath}/arms/Arms Under - Assault Rifles.png`;
const armsLeftBlackspotLImage = `${imagePath}/arms/Arms Under - Blackspot L.png`;
const armsLeftHandsImage = `${imagePath}/arms/Arms Under - Hands.png`;
const armsLeftShrampHandsImage = `${imagePath}/arms/Arms Under - Shramp Hands.png`;
const armsLeftTorchesImage = `${imagePath}/arms/Arms Under - Torches.png`;
// RIGHT
const armsRightAssaultRiflesImage = `${imagePath}/arms/Arms Over - Assault Rifles.png`;
const armsRightBlackspotRImage = `${imagePath}/arms/Arms Over - Blackspot R.png`;
const armsRightHandsImage = `${imagePath}/arms/Arms Over - Hands.png`;
const armsRightShrampHandsImage = `${imagePath}/arms/Arms Over - Shramp Hands.png`;
const armsRightTorchesImage = `${imagePath}/arms/Arms Over - Torches.png`;

// FRONT
const frontAssaultRiflesImage = `${imagePath}/front/Front - Assault Rifles.png`;
const frontBallTurretImage = `${imagePath}/front/Front - Ball Turret.png`;
const frontCableWinchImage = `${imagePath}/front/Front - Cable Winch.png`;
const frontGatlingImage = `${imagePath}/front/Front - Gatling.png`;
const frontMissilePodsImage = `${imagePath}/front/Front - Missile Pods.png`;
const frontOracleLMGImage = `${imagePath}/front/Front - Oracle LMG.png`;

// ACCESSORY
const accChassisGravPadsImage = `${imagePath}/accessory/Chassis 1 - Grav Pads.png`;
const accChassisHeavyThrustersImage = `${imagePath}/accessory/Chassis 1 - Heavy Thrusters.png`;
const accChassisHeavyThrustersUnderImage = `${imagePath}/accessory/Chassis 1 - Heavy Thrusters-under.png`;
const accGlowFrontArmorImage = `${imagePath}/accessory/Glow - Front Armor.png`;
const accShrampCommArrayImage = `${imagePath}/accessory/Shramp Accessories - Comm Array.png`;
const accShrampMissilesImage = `${imagePath}/accessory/Shramp Accessories - Missiles.png`;
const accShrampStrapsImage = `${imagePath}/accessory/Shramp Accessories - Straps.png`;

// GLOW
const glowBlackspotLImage = `${imagePath}/glows/Glow - Blackspot L.png`;
const glowBlackspotRImage = `${imagePath}/glows/Glow - Blackspot R.png`;
const glowBlackspotsImage = `${imagePath}/glows/Glow - Blackspots.png`;
const glowGravPadsImage = `${imagePath}/glows/Glow - Grav Pads.png`;
const glowGravPadsArcsImage = `${imagePath}/glows/Glow - Grav Pads Arcs.png`;
const glowHeavyThrustersImage = `${imagePath}/glows/Glow - Heavy Thrusters.png`;
const glowHeavyThrustersUnderImage = `${imagePath}/glows/Glow - Heavy Thrusters-under.png`;
const glowHeavyThrustersAWACSImage = `${imagePath}/glows/Glow - Heavy Thrusters AWACS.png`;
const glowTorchesImage = `${imagePath}/glows/Glow - Torches.png`;

const previewImage = `${imagePath}/preview.png`;

const core1Image = `${imagePath}/tax_corebook_1_mechamaria64.png`;
const core2Image = `${imagePath}/tax_corebook_2_mechamaria64.png`;

const bipedFlightShadow = {
    size: shadowTypes.LARGE,
    bottom: "3px",
    right: "9px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: core1Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "MechaMaria64",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core2Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "MechaMaria64",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "2",
        "weaponValue": "2",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "1",
        "accessory2Value": "NONE",
        "accessory3Value": "10",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "ANTENNAE",
            src: topAntennaeImage,
        },
        {
            value: 1,
            label: "AWACS",
            src: topAWACSImage,
        },
        {
            value: 2,
            label: "BALL TURRET",
            src: topBallTurretImage,
        },
        {
            value: 3,
            label: "CARGO BRACES",
            src: topCargoBracesImage,
        },
        {
            value: 4,
            label: "CHASSIS ADDITION",
            src: topChassisAdditionImage,
        },
        {
            value: 5,
            label: "MISSILES",
            src: topMissilesImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "TARAXACUM",
            src: bodyImage,
            shadowDetails: bipedFlightShadow,
            originalArtCredit: {
                prefixText: "Original art by",
                title: "Naruga",
                url: "https://bsky.app/profile/naruga.bsky.social",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "AR x AR",
                src: armsRightAssaultRiflesImage,
                backSrc: armsLeftAssaultRiflesImage,
            },
            {
                value: 1,
                label: "BLACKSPOT x BLACKSPOT",
                src: armsRightBlackspotRImage,
                backSrc: armsLeftBlackspotLImage,
            },
            {
                value: 2,
                label: "HANDS x HANDS",
                src: armsRightHandsImage,
                backSrc: armsLeftHandsImage,
            },
            {
                value: 3,
                label: "SHRAMP HANDS x SHRAMP HANDS",
                src: armsRightShrampHandsImage,
                backSrc: armsLeftShrampHandsImage,
            },
            {
                value: 4,
                label: "TORCHES x TORCHES",
                src: armsRightTorchesImage,
                backSrc: armsLeftTorchesImage,
            },
            {
                value: 5,
                label: "AR x NONE",
                backSrc: armsLeftAssaultRiflesImage,
            },
            {
                value: 6,
                label: "AR x BLACKSPOT",
                src: armsRightBlackspotRImage,
                backSrc: armsLeftAssaultRiflesImage,
            },
            {
                value: 7,
                label: "AR x HANDS",
                src: armsRightHandsImage,
                backSrc: armsLeftAssaultRiflesImage,
            },
            {
                value: 8,
                label: "AR x SHRAMP HANDS",
                src: armsRightShrampHandsImage,
                backSrc: armsLeftAssaultRiflesImage,
            },
            {
                value: 9,
                label: "AR x TORCHES",
                src: armsRightTorchesImage,
                backSrc: armsLeftAssaultRiflesImage,
            },
            {
                value: 10,
                label: "NONE x AR",
                src: armsRightAssaultRiflesImage,
            },
            {
                value: 11,
                label: "BLACKSPOT x AR",
                src: armsRightAssaultRiflesImage,
                backSrc: armsLeftBlackspotLImage,
            },
            {
                value: 12,
                label: "HANDS x AR",
                src: armsRightAssaultRiflesImage,
                backSrc: armsLeftHandsImage,
            },
            {
                value: 13,
                label: "SHRAMP HANDS x AR",
                src: armsRightAssaultRiflesImage,
                backSrc: armsLeftShrampHandsImage,
            },
            {
                value: 14,
                label: "TORCHES x AR",
                src: armsRightAssaultRiflesImage,
                backSrc: armsLeftTorchesImage,
            },
            {
                value: 15,
                label: "BLACKSPOT x NONE",
                backSrc: armsLeftBlackspotLImage,
            },
            {
                value: 16,
                label: "BLACKSPOT x HANDS",
                src: armsRightHandsImage,
                backSrc: armsLeftBlackspotLImage,
            },
            {
                value: 17,
                label: "BLACKSPOT x SHRAMP HANDS",
                src: armsRightShrampHandsImage,
                backSrc: armsLeftBlackspotLImage,
            },
            {
                value: 18,
                label: "BLACKSPOT x TORCHES",
                src: armsRightTorchesImage,
                backSrc: armsLeftBlackspotLImage,
            },
            {
                value: 19,
                label: "NONE x BLACKSPOT",
                src: armsRightBlackspotRImage,
            },
            {
                value: 20,
                label: "HANDS x BLACKSPOT",
                src: armsRightBlackspotRImage,
                backSrc: armsLeftHandsImage,
            },
            {
                value: 21,
                label: "SHRAMP HANDS x BLACKSPOT",
                src: armsRightBlackspotRImage,
                backSrc: armsLeftShrampHandsImage,
            },
            {
                value: 22,
                label: "TORCHES x BLACKSPOT",
                src: armsRightBlackspotRImage,
                backSrc: armsLeftTorchesImage,
            },
            {
                value: 23,
                label: "HANDS x NONE",
                backSrc: armsLeftHandsImage,
            },
            {
                value: 24,
                label: "HANDS x SHRAMP HANDS",
                src: armsRightShrampHandsImage,
                backSrc: armsLeftHandsImage,
            },
            {
                value: 25,
                label: "HANDS x TORCHES",
                src: armsRightTorchesImage,
                backSrc: armsLeftHandsImage,
            },
            {
                value: 26,
                label: "NONE x HANDS",
                src: armsRightHandsImage,
            },
            {
                value: 27,
                label: "SHRAMP HANDS x HANDS",
                src: armsRightHandsImage,
                backSrc: armsLeftShrampHandsImage,
            },
            {
                value: 28,
                label: "TORCHES x HANDS",
                src: armsRightHandsImage,
                backSrc: armsLeftTorchesImage,
            },
            {
                value: 29,
                label: "SHRAMP HANDS x NONE",
                backSrc: armsLeftShrampHandsImage,
            },
            {
                value: 30,
                label: "SHRAMP HANDS x TORCHES",
                src: armsRightTorchesImage,
                backSrc: armsLeftShrampHandsImage,
            },
            {
                value: 31,
                label: "NONE x SHRAMP HANDS",
                src: armsRightShrampHandsImage,
            },
            {
                value: 32,
                label: "TORCHES x SHRAMP HANDS",
                src: armsRightShrampHandsImage,
                backSrc: armsLeftTorchesImage,
            },
            {
                value: 33,
                label: "TORCHES x NONE",
                backSrc: armsLeftTorchesImage,
            },
            {
                value: 34,
                label: "NONE x TORCHES",
                src: armsRightTorchesImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "ASSAULT RIFLES",
            src: frontAssaultRiflesImage,
        },
        {
            value: 1,
            label: "BALL TURRET",
            src: frontBallTurretImage,
        },
        {
            value: 2,
            label: "CABLE WINCH",
            src: frontCableWinchImage,
        },
        {
            value: 3,
            label: "GATLING",
            src: frontGatlingImage,
        },
        {
            value: 4,
            label: "MISSILE PODS",
            src: frontMissilePodsImage,
        },
        {
            value: 5,
            label: "ORACLE LMG",
            src: frontOracleLMGImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "BALL TURRET",
            frontSrc: lowerBallTurretImage,
        },
        {
            value: 1,
            label: "BAR LANDING GEAR",
            frontSrc: lowerBarLandingGearImage,
        },
        {
            value: 2,
            label: "BIPED DOWN",
            frontSrc: lowerBipedDownImage,
        },
        {
            value: 3,
            label: "BIPED RETRACTED",
            frontSrc: lowerBipedRetractedImage,
        },
        {
            value: 4,
            label: "CARGO CRANE",
            frontSrc: lowerCargoCraneImage,
        },
        {
            value: 5,
            label: "QUAD LEGS",
            frontSrc: lowerQuadLegsImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "FRONT ARMOR",
            src: accGlowFrontArmorImage,
        },
        {
            value: 1,
            label: "GRAV PADS",
            src: accChassisGravPadsImage,
        },
        {
            value: 2,
            label: "HEAVY THRUSTERS",
            src: accChassisHeavyThrustersImage,
            backmostSrc: accChassisHeavyThrustersUnderImage,
        },
        {
            value: 3,
            label: "SHRAMP - CHASSIS",
            src: body2Image,
        },
        {
            value: 4,
            label: "SHRAMP - COMM ARRAY",
            frontSrc: accShrampCommArrayImage,
        },
        {
            value: 5,
            label: "SHRAMP - MISSILES",
            frontSrc: accShrampMissilesImage,
        },
        {
            value: 6,
            label: "SHRAMP - STRAPS",
            frontSrc: accShrampStrapsImage,
        },
        {
            value: 7,
            label: "GLOW - BLACKSPOT L",
            src: glowBlackspotLImage,
        },
        {
            value: 8,
            label: "GLOW - BLACKSPOT R",
            frontSrc: glowBlackspotRImage,
        },
        {
            value: 9,
            label: "GLOW - BLACKSPOTS",
            frontSrc: glowBlackspotsImage,
        },
        {
            value: 10,
            label: "GLOW - GRAV PADS",
            frontSrc: glowGravPadsImage,
        },
        {
            value: 11,
            label: "GLOW - GRAV PADS + ARCS",
            frontSrc: glowGravPadsArcsImage,
        },
        {
            value: 12,
            label: "GLOW - HEAVY THRUSTERS",
            frontSrc: glowHeavyThrustersImage,
            backSrc: glowHeavyThrustersUnderImage,
        },
        {
            value: 13,
            label: "GLOW - HEAVY THRUSTERS AWACS",
            frontSrc: glowHeavyThrustersAWACSImage,
            backSrc: glowHeavyThrustersUnderImage,
        },
        {
            value: 14,
            label: "GLOW - TORCHES",
            frontSrc: glowTorchesImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "ARMS",
            value: "WEAPONS",
        },
        {
            label: "ACCESSORY / GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "TOP MOUNT",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "ARMS",
            value: "weapon",
        },
        {
            label: "FRONT MOUNT",
            value: "secondaryWeapon",
        },
        {
            label: "LOWER MOUNT",
            value: "rearMount",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "GLOW 1",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "TOP MOUNT",
        "chassis": "CHASSIS",
        "rearMount": "LOWER MOUNT",
        "weapon": "ARMS",
        "secondaryWeapon": "FRONT MOUNT",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "GLOW 1",
        "accessory4": "GLOW 2",
    },
};
