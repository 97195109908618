import akrafena from "./Mechs-BDF-Akrafena";
import rittaya from "./Mechs-Rittaya";
import jian from "./Mechs-BDF-Jian";
import dao from "./Mechs-BDF-Dao";
import daoSubalts from "./Mechs-BDF-Dao-Subalterns";
import ibutho from "./Mechs-Ibutho";
import frankenstein from "./Mechs-MOS-Frankenstein";
import dfgWrath from "./Mechs-DFG-Wrath";
import solomon from "./Mechs-MOI-Solomon";
import solomonDrones from "./Mechs-MOI-Solomon-Drones";
import rasputin from "./Mechs-MOS-Rasputin";
import dfgGluttony from "./Mechs-DFG-Gluttony";
import ptolemy from "./Mechs-MOI-Ptolemy";
import donner from "./Mechs-MOS-Donner";
import rakshasa from "./Mechs-Rakshasa";
import gilgamesh from "./Mechs-Gilgamesh";
import dfgEnvy from "./Mechs-DFG-Envy";
import loza from "./Mechs-Loza";
import charioteer from "./Mechs-Charioteer";
import amakusa from "./Mechs-MOI-Amakusa";
import medea from "./Mechs-MOI-Medea";
import medeaDrones from "./Mechs-MOI-Medea-Drones";
import MechsMFCGrootslang from "./Mechs-MFC-Grootslang";
import MechsDFGAvarice from "./Mechs-DFG-Avarice";
import zahhak from "./Mechs-MOS-Zahhak";
import MechsChrysoprase from "./Mechs-Chrysoprase";
import MechsCoromuel from "./Mechs-Coromuel";
import MechsMOINimue from "./Mechs-MOI-Nimue";
import MechsKalista from "./Mechs-Kalista";
import arthur from "./Mechs-HA-Arthur";
import khanda from "./Mechs-BDF-Khanda";
import phoenix from "./Mechs-MOS-Phoenix";
import MechsPhillipides from "./Mechs-Phillipides";
import MechsBligh from "./Mechs-Bligh";
import MechsBlighDrones from "./Mechs-Bligh-Drones";
import MechsMOIMorgana from "./Mechs-MOI-Morgana";
import MechsHAHapsburg from "./Mechs-HA-Hapsburg";
import MechsDFGSloth from "./Mechs-DFG-Sloth";
import akinaka from "./Mechs-BDF-Akinaka";
import akinakaDrones from "./Mechs-BDF-Akinaka-Drones";
import MechsMIAThalia from "./Mechs-MIA-Thalia";
import MechsBDFKlewang from "./Mechs-BDF-Klewang";
import MechsSalamander from "./Mechs-Salamander";
import MechsSSCOleander from "./Mechs-SSC-Oleander";
import MechsDFGAcedia from "./Mechs-DFG-Acedia";
import MechsTogoRTG from "./Mechs-TogoRTG";
import MechsRemus from "./Mechs-Remus";
import MechsMOSCeleste from "./Mechs-MOS-Celeste";
import MechsFBTThor from "./Mechs-FBT-Thor";
import MechsISPNCrab from "./Mechs-ISPN-Crab";
import MechsAFAnckarstrom from "./Mechs-AF-Anckarstrom";
import MechsAAAwokera from "./Mechs-AA-Awokera";
import MechsAAAni from "./Mechs-AA-Ani";

export default [
    Object.assign({ value: 0, label: "AA ANI" }, MechsAAAni),
    Object.assign({ value: 1, label: "AA AWOKERA" }, MechsAAAwokera),
    Object.assign({ value: 2, label: "AF ANCKARSTROM" }, MechsAFAnckarstrom),
    Object.assign({ value: 3, label: "AUTOCHTHON MAKHANA CHRYSOPRASE *" }, MechsChrysoprase),
    Object.assign({ value: 4, label: "AXK ZAWISZA", isPremium: true, isLegendary: true }, rittaya),
    Object.assign({ value: 5, label: "BDF AKINAKA", isPremium: true, isLegendary: true }, akinaka),
    Object.assign({ value: 6, label: "BDF AKINAKA - DRONES" }, akinakaDrones),
    Object.assign({ value: 7, label: "BDF AKRAFENA", isPremium: true, isLegendary: true }, akrafena),
    Object.assign({ value: 8, label: "BDF DAO", isPremium: true, isLegendary: true }, dao),
    Object.assign({ value: 9, label: "BDF DAO - SUBALTERNS", isPremium: true, isLegendary: true }, daoSubalts),
    Object.assign({ value: 10, label: "BDF JIAN", isPremium: true, isLegendary: true }, jian),
    Object.assign({ value: 11, label: "BDF KHANDA", isPremium: true, isLegendary: true }, khanda),
    Object.assign({ value: 12, label: "BDF KLEWANG", isPremium: true, isLegendary: true }, MechsBDFKlewang),
    Object.assign({ value: 13, label: "C&H CHARIOTEER *" }, charioteer),
    Object.assign({ value: 14, label: "C&H KALISTA *" }, MechsKalista),
    Object.assign({ value: 15, label: "DFG ACEDIA" }, MechsDFGAcedia),
    Object.assign({ value: 16, label: "DFG AVARICE" }, MechsDFGAvarice),
    Object.assign({ value: 17, label: "DFG ENVY" }, dfgEnvy),
    Object.assign({ value: 18, label: "DFG GLUTTONY" }, dfgGluttony),
    Object.assign({ value: 19, label: "DFG SLOTH" }, MechsDFGSloth),
    Object.assign({ value: 20, label: "DFG WRATH" }, dfgWrath),
    Object.assign({ value: 21, label: "FBT THOR *" }, MechsFBTThor),
    Object.assign({ value: 22, label: "HA ARTHUR *" }, arthur),
    Object.assign({ value: 23, label: "HA HABSBURG" }, MechsHAHapsburg),
    Object.assign({ value: 24, label: "HA LOZA" }, loza),
    Object.assign({ value: 25, label: "HA REMUS *" }, MechsRemus),
    Object.assign({ value: 26, label: "HORUS RAKSHASA" }, rakshasa),
    Object.assign({ value: 27, label: "HORUS SALAMANDER" }, MechsSalamander),
    Object.assign({ value: 28, label: "IF-DRACOS PTHALLFIRE *" }, gilgamesh),
    Object.assign({ value: 29, label: "IMI COROMUEL *" }, MechsCoromuel),
    Object.assign({ value: 30, label: "IPS-N BLIGH" }, MechsBligh),
    Object.assign({ value: 31, label: "IPS-N BLIGH SWABBER DRONES" }, MechsBlighDrones),
    Object.assign({ value: 32, label: "IPS-N CRAB *" }, MechsISPNCrab),
    Object.assign({ value: 33, label: "IPS-N TOGO" }, MechsTogoRTG),
    Object.assign({ value: 34, label: "MFC GROOTSLANG" }, MechsMFCGrootslang),
    Object.assign({ value: 35, label: "MFC IBUTHO" }, ibutho),
    Object.assign({ value: 36, label: "MIA THALIA" }, MechsMIAThalia),
    Object.assign({ value: 37, label: "MOI AMAKUSA" }, amakusa),
    Object.assign({ value: 38, label: "MOI MEDEA" }, medea),
    Object.assign({ value: 39, label: "MOI MEDEA DRONES" }, medeaDrones),
    Object.assign({ value: 40, label: "MOI MORGANA" }, MechsMOIMorgana),
    Object.assign({ value: 41, label: "MOI NIMUE" }, MechsMOINimue),
    Object.assign({ value: 42, label: "MOI PTOLEMY" }, ptolemy),
    Object.assign({ value: 43, label: "MOI SOLOMON" }, solomon),
    Object.assign({ value: 44, label: "MOI SOLOMON DRONES" }, solomonDrones),
    Object.assign({ value: 45, label: "MO&S CELESTE" }, MechsMOSCeleste),
    Object.assign({ value: 46, label: "MO&S DONNER" }, donner),
    Object.assign({ value: 47, label: "MO&S FRANKENSTEIN" }, frankenstein),
    Object.assign({ value: 48, label: "MO&S PHOENIX" }, phoenix),
    Object.assign({ value: 49, label: "MO&S RASPUTIN" }, rasputin),
    Object.assign({ value: 50, label: "MO&S ZAHHAK" }, zahhak),
    Object.assign({ value: 51, label: "SA PHILLIPIDES" }, MechsPhillipides),
    Object.assign({ value: 52, label: "SSC OLEANDER" }, MechsSSCOleander),
];
