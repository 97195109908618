import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/AA-Ani`;

// BODY
const bodyImage = `${imagePath}/Ani Chassis - Flight.png`;
const body2Image = `${imagePath}/Ani Chassis - Grounded.png`;

// HEADS
const headAniImage = `${imagePath}/heads/Head - Ani.png`;
const head8BallImage = `${imagePath}/heads/Head - 8 Ball.png`;
const headBlackspotImage = `${imagePath}/heads/Head - Blackspot.png`;
const headCalibanImage = `${imagePath}/heads/Head - Caliban.png`;
const headCheddahImage = `${imagePath}/heads/Head - Cheddah.png`;
const headChimeraImage = `${imagePath}/heads/Head - Chimera.png`;
const headCyclopsImage = `${imagePath}/heads/Head - Cyclops.png`;
const headDomImage = `${imagePath}/heads/Head - Dom.png`;
const headDungamImage = `${imagePath}/heads/Head - Dungam.png`;
const headEnkiduImage = `${imagePath}/heads/Head - Enkidu.png`;
const headFTNelsonImage = `${imagePath}/heads/Head - FT Nelson.png`;
const headHornedImage = `${imagePath}/heads/Head - Horned.png`;
const headHornedHairImage = `${imagePath}/heads/Head - Horned Hair.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headLaborerImage = `${imagePath}/heads/Head - Laborer.png`;
const headLycanImage = `${imagePath}/heads/Head - Lycan.png`;
const headMagImage = `${imagePath}/heads/Head - Mag.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headMecha1Image = `${imagePath}/heads/Head - Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Head - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Head - Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/Head - Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/Head - Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/Head - Mecha 8.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Head - Natural Law Hair.png`;
const headNelsonImage = `${imagePath}/heads/Head - Nelson.png`;
const headNemesisImage = `${imagePath}/heads/Head - Nemesis.png`;
const headRainImage = `${imagePath}/heads/Head - Rain Transformed.png`;
const headRainHairImage = `${imagePath}/heads/Head - Rain Transformed Hair.png`;
const headRinzlerImage = `${imagePath}/heads/Head - Rinzler.png`;
const headSkull3Image = `${imagePath}/heads/Head - Skull 3.png`;
const headTokugawaImage = `${imagePath}/heads/Head - Tokugawa.png`;
const headVanquisherImage = `${imagePath}/heads/Head - Vanquisher.png`;
const headViceroyImage = `${imagePath}/heads/Head - Viceroy.png`;
const headVisionaryImage = `${imagePath}/heads/Head - Visionary.png`;

// WEAPONS
// LEFT
const weaponsLeftARImage = `${imagePath}/weapons/left/Left Arm - AR.png`;
const weaponsLeftLauncherImage = `${imagePath}/weapons/left/Left Arm - Launcher.png`;
const weaponsLeftFerrofluidImage = `${imagePath}/weapons/left/Left Arm - Ferrofluid.png`;
const weaponsLeftFistImage = `${imagePath}/weapons/left/Left Arm - Fist.png`;
const weaponsLeftHMGImage = `${imagePath}/weapons/left/Left Arm - HMG.png`;
const weaponsLeftHMGunderImage = `${imagePath}/weapons/left/Left Arm - HMG under.png`;
// RIGHT
const weaponsRightFerrofluidImage = `${imagePath}/weapons/right/Right Arm - Ferrofluid.png`;
const weaponsRightFistImage = `${imagePath}/weapons/right/Right Arm - Fist.png`;
const weaponsRightHMGImage = `${imagePath}/weapons/right/Right Arm - HMG.png`;
const weaponsRightHMGunderImage = `${imagePath}/weapons/right/Right Arm - HMG under.png`;
const weaponsRightLauncherImage = `${imagePath}/weapons/right/Right Arm - Launcher.png`;
const weaponsRightQuillDrillImage = `${imagePath}/weapons/right/Right Arm - Quill Drill.png`;

// REAR MOUNT
const rearBoostersImage = `${imagePath}/rearmount/Flight Mount - Boosters.png`;
const rearWingsImage = `${imagePath}/rearmount/Flight Mount - Wings.png`;

// ACCESSORY
const accessoryChaffLaunchersImage = `${imagePath}/accessory/Accessory - Chaff Launchers.png`;
const accessoryHandsImage = `${imagePath}/accessory/Wing Mounts - Hands.png`;
const accessoryLaserPistolsImage = `${imagePath}/accessory/Wing Mounts - Laser Pistols.png`;
const accessorySMGsImage = `${imagePath}/accessory/Wing Mounts - SMGs.png`;
const accessoryFerrofluidImage = `${imagePath}/accessory/Wing Options - Ferrofluid.png`;
const accessoryFerrofluidunderImage = `${imagePath}/accessory/Wing Options - Ferrofluid under.png`;
const accessoryMissilesoverImage = `${imagePath}/accessory/Wing Options - Missiles over.png`;
const accessoryMissilesUnderImage = `${imagePath}/accessory/Wing Options - Missiles under.png`;
const accessoryQuillsImage = `${imagePath}/accessory/Wing Options - Quills.png`;
const accessoryWingExtensionsImage = `${imagePath}/accessory/Wing Options - Wing Extensions.png`;

// GLOW
const glowBoostersImage = `${imagePath}/glows/Glow 1 - Boosters.png`;
const glowBoostersunderImage = `${imagePath}/glows/Glow 1 - Boosters under.png`;
const glowBasicWingsImage = `${imagePath}/glows/Glow 1 - Basic Wings.png`;
const glowBasicWingsExtensionsImage = `${imagePath}/glows/Glow 1 - Basic Wings Extensions.png`;

const previewImage = `${imagePath}/preview.png`;

const core1Image = `${imagePath}/corebook_ani-toast.png`;
const core2Image = `${imagePath}/corebook_ani_clean-toast.png`;

const bipedFlightShadow = {
    size: shadowTypes.LARGE,
    bottom: "3px",
    right: "9px",
};

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "49px",
    right: "43px",
};

export default {
    previewImg: previewImage,
    isMegadeus: true,
    accessory2IsChassis: true,
    coreImg: [
        {
            src: core1Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "snipertoaster",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core2Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "snipertoaster",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "1",
        "weaponValue": "0",
        "secondaryWeaponValue": "4",
        "accessory1Value": "1",
        "accessory2Value": "NONE",
        "accessory3Value": "9",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "ANI",
            src: headAniImage,
        },
        {
            value: 1,
            label: "8 BALL",
            src: head8BallImage,
        },
        {
            value: 2,
            label: "BLACKSPOT",
            src: headBlackspotImage,
        },
        {
            value: 3,
            label: "CALIBAN",
            src: headCalibanImage,
        },
        {
            value: 4,
            label: "CHEDDAH",
            src: headCheddahImage,
        },
        {
            value: 5,
            label: "CHIMERA",
            src: headChimeraImage,
        },
        {
            value: 6,
            label: "CYCLOPS",
            src: headCyclopsImage,
        },
        {
            value: 7,
            label: "DOM",
            src: headDomImage,
        },
        {
            value: 8,
            label: "DUNGAM",
            src: headDungamImage,
        },
        {
            value: 9,
            label: "ENKIDU",
            src: headEnkiduImage,
        },
        {
            value: 10,
            label: "FT NELSON",
            src: headFTNelsonImage,
        },
        {
            value: 11,
            label: "HORNED",
            src: headHornedImage,
        },
        {
            value: 12,
            label: "HORNED HAIR",
            src: headHornedHairImage,
        },
        {
            value: 13,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 14,
            label: "LABORER",
            src: headLaborerImage,
        },
        {
            value: 15,
            label: "LYCAN",
            src: headLycanImage,
        },
        {
            value: 16,
            label: "MAG",
            src: headMagImage,
        },
        {
            value: 17,
            label: "MALICE",
            src: headMaliceImage,
        },
        {
            value: 18,
            label: "MECHA 1",
            src: headMecha1Image,
        },
        {
            value: 19,
            label: "MECHA 2",
            src: headMecha2Image,
        },
        {
            value: 20,
            label: "MECHA 3",
            src: headMecha3Image,
        },
        {
            value: 21,
            label: "MECHA 4",
            src: headMecha4Image,
        },
        {
            value: 22,
            label: "MECHA 5",
            src: headMecha5Image,
        },
        {
            value: 23,
            label: "MECHA 6",
            src: headMecha6Image,
        },
        {
            value: 24,
            label: "MECHA 7",
            src: headMecha7Image,
        },
        {
            value: 25,
            label: "MECHA 8",
            src: headMecha8Image,
        },
        {
            value: 26,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 27,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
        },
        {
            value: 28,
            label: "NELSON",
            src: headNelsonImage,
        },
        {
            value: 29,
            label: "NEMESIS",
            src: headNemesisImage,
        },
        {
            value: 30,
            label: "RAIN",
            src: headRainImage,
        },
        {
            value: 31,
            label: "RAIN HAIR",
            src: headRainHairImage,
        },
        {
            value: 32,
            label: "RINZLER",
            src: headRinzlerImage,
        },
        {
            value: 33,
            label: "SKULL 3",
            src: headSkull3Image,
        },
        {
            value: 34,
            label: "TOKUGAWA",
            src: headTokugawaImage,
        },
        {
            value: 35,
            label: "VANQUISHER",
            src: headVanquisherImage,
        },
        {
            value: 36,
            label: "VICEROY",
            src: headViceroyImage,
        },
        {
            value: 37,
            label: "VISIONARY",
            src: headVisionaryImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "FLIGHT",
            src: bodyImage,
            shadowDetails: bipedFlightShadow,
            originalArtCredit: {
                prefixText: "Commissioned by magdalene :) ",
                title: "Far-Field Transmission Log",
                url: "https://popocculture.itch.io/uf-a",
                secondaryPrefixText: "Original art by",
                secondaryTitle: "Naruga",
                secondaryUrl: "https://bsky.app/profile/naruga.bsky.social",
            },
        },
        {
            value: 1,
            label: "GROUNDED",
            src: body2Image,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by magdalene :) ",
                title: "Far-Field Transmission Log",
                url: "https://popocculture.itch.io/uf-a",
                secondaryPrefixText: "Original art by",
                secondaryTitle: "Naruga",
                secondaryUrl: "https://bsky.app/profile/naruga.bsky.social",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "AR",
                src: weaponsLeftARImage,
            },
            {
                value: 1,
                label: "LAUNCHER",
                src: weaponsLeftLauncherImage,
            },
            {
                value: 2,
                label: "FERROFLUID",
                src: weaponsLeftFerrofluidImage,
            },
            {
                value: 3,
                label: "FIST",
                src: weaponsLeftFistImage,
            },
            {
                value: 4,
                label: "HMG",
                src: weaponsLeftHMGImage,
                backSrc: weaponsLeftHMGunderImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "FERROFLUID",
            src: weaponsRightFerrofluidImage,
        },
        {
            value: 1,
            label: "FIST",
            src: weaponsRightFistImage,
        },
        {
            value: 2,
            label: "HMG",
            src: weaponsRightHMGImage,
            backSrc: weaponsRightHMGunderImage,
        },
        {
            value: 3,
            label: "LAUNCHER",
            src: weaponsRightLauncherImage,
        },
        {
            value: 4,
            label: "QUILL DRILL",
            src: weaponsRightQuillDrillImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "BOOSTERS",
            src: rearBoostersImage,
        },
        {
            value: 1,
            label: "WINGS",
            src: rearWingsImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "CHAFF LAUNCHERS",
            src: accessoryChaffLaunchersImage,
        },
        {
            value: 1,
            label: "HANDS",
            src: accessoryHandsImage,
        },
        {
            value: 2,
            label: "LASER PISTOLS",
            src: accessoryLaserPistolsImage,
        },
        {
            value: 3,
            label: "SMG",
            src: accessorySMGsImage,
        },
        {
            value: 4,
            label: "FERROFLUID",
            src: accessoryFerrofluidImage,
            backSrc: accessoryFerrofluidunderImage,
        },
        {
            value: 5,
            label: "MISSILES",
            src: accessoryMissilesoverImage,
            backSrc: accessoryMissilesUnderImage,
        },
        {
            value: 6,
            label: "QUILLS",
            src: accessoryQuillsImage,
        },
        {
            value: 7,
            label: "WING EXTENSIONS",
            src: accessoryWingExtensionsImage,
        },
        {
            value: 8,
            label: "GLOW - BOOSTERS",
            src: glowBoostersImage,
            backSrc: glowBoostersunderImage,
        },
        {
            value: 9,
            label: "GLOW - WINGS",
            src: glowBasicWingsImage,
        },
        {
            value: 10,
            label: "GLOW - WINGS EXTENSIONS",
            src: glowBasicWingsExtensionsImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "ACCESSORY / GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "L WEAPON",
            value: "weapon",
        },
        {
            label: "R WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "FLIGHT MOUNT",
            value: "rearMount",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "GLOW 1",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "FLIGHT MOUNT",
        "weapon": "L WEAPON",
        "secondaryWeapon": "R WEAPON",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "GLOW 1",
        "accessory4": "GLOW 2",
    },
};
