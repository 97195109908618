import BaseURL from "./images/Hosting-BaseURL";
import { shadowTypes } from "../styles/UnitShadow";

const imagePath = `${BaseURL}/Mechs/Custom/MOI-Morgana`;

// BODY
const bodyMorganaImage = `${imagePath}/Chassis - Morgana.png`;
const bodyMorgana2Image = `${imagePath}/Chassis - Morgana 2.png`;
const bodyMorgana12ArmsImage = `${imagePath}/Chassis - Morgana 12 Arms.png`;
const collarCollar1Image = `${imagePath}/Chassis Collar 1.png`;
const collarCollar2Image = `${imagePath}/Chassis Collar 2.png`;
const collarCollar6armsImage = `${imagePath}/Chest - Collar 6 arms.png`;
const collarCollar12armsImage = `${imagePath}/Chest - Collar 12 arms.png`;

// HEADS
const headMorganaImage = `${imagePath}/heads/Head - Morgana.png`;
const head8BallImage = `${imagePath}/heads/Head - 8Ball.png`;
const headBlackspotImage = `${imagePath}/heads/Head - Blackspot.png`;
const headCalibanImage = `${imagePath}/heads/Head - Caliban.png`;
const headCheddahImage = `${imagePath}/heads/Head - Cheddah.png`;
const headChimeraImage = `${imagePath}/heads/Head - Chimera.png`;
const headCyclopsImage = `${imagePath}/heads/Head - Cyclops.png`;
const headDomImage = `${imagePath}/heads/Head - Dom.png`;
const headDungamImage = `${imagePath}/heads/Head - Dungam.png`;
const headHoodImage = `${imagePath}/heads/Head - Hood.png`;
const headHornedHairImage = `${imagePath}/heads/Head - Horned Hair.png`;
const headHornedImage = `${imagePath}/heads/Head - Horned.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headLaborerImage = `${imagePath}/heads/Head - Laborer.png`;
const headMagImage = `${imagePath}/heads/Head - Mag.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headMecha1Image = `${imagePath}/heads/Head - Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Head - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Head - Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/Head - Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/Head - Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/Head - Mecha 8.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Head - Natural Law Hair.png`;
const headNelsonImage = `${imagePath}/heads/Head - Nelson.png`;
const headNelsonFlightTypeImage = `${imagePath}/heads/Head - Nelson Flight Type.png`;
const headNemesisImage = `${imagePath}/heads/Head - Nemesis.png`;
const headRainTransformedImage = `${imagePath}/heads/Head - Rain Transformed.png`;
const headRainHairImage = `${imagePath}/heads/Head - Rain Hair.png`;
const headSkullImage = `${imagePath}/heads/Head - Skull.png`;
const headTokugawaImage = `${imagePath}/heads/Head - Tokugawa.png`;
const headVanquisherImage = `${imagePath}/heads/Head - Vanquisher.png`;
const headViceroyImage = `${imagePath}/heads/Head - Viceroy.png`;
const headVisionaryImage = `${imagePath}/heads/Head - Visionary.png`;
const headVisionary2Image = `${imagePath}/heads/Head - Visionary 2.png`;

// ARMS
const armsOverDefaultImage = `${imagePath}/arms/Ams Over - Default.png`;
const armsOverQuadSMGsImage = `${imagePath}/arms/Arms Over - Quad SMGs.png`;
const armsOverSMGImage = `${imagePath}/arms/Arms Over - SMG.png`;
const armsOver12Image = `${imagePath}/arms/Arms Over -12.png`;
const armsUnder6Image = `${imagePath}/arms/Arms Under - 6.png`;

// GLOWS
const glowArms6Image = `${imagePath}/glows/Glow - Arms 6.png`;
const glow6ArmsHackingImage = `${imagePath}/glows/Glow - 6 Arms Hacking.png`;
const glowArms6SMGImage = `${imagePath}/glows/Glow - Arms 6 SMG.png`;
const glowArmsUnderImage = `${imagePath}/glows/Glow - Arms Under.png`;
const glowArmsAllImage = `${imagePath}/glows/Glow - Arms All.png`;
const glowHackingImage = `${imagePath}/glows/Glow - Hacking.png`;
const glowHackingSimpleImage = `${imagePath}/glows/Glow - Hacking Simple.png`;
const glowAllArmsHackingImage = `${imagePath}/glows/Glow - All Arms Hacking.png`;
const glowMorganaChestImage = `${imagePath}/glows/Glow - Morgana Chest.png`;
const glowMorgana2Image = `${imagePath}/glows/Glow - Morgana 2.png`;
const glowMorganaEnergyTrailsImage = `${imagePath}/glows/Glow - Morgana Energy Trails.png`;
const glowMorganaHeadImage = `${imagePath}/glows/Glow - Morgana Head.png`;
const glowMorganaHeadEnergyImage = `${imagePath}/glows/Glow - Morgana Head Energy.png`;
const glowPortalImage = `${imagePath}/glows/Glow Under - Portal.png`;
const glowPortalAsteroidsImage = `${imagePath}/glows/Glow Under - Portal Asteroids.png`;
const glowPortalBlackImage = `${imagePath}/glows/Glow Under - Portal Black.png`;

const previewImage = `${imagePath}/preview.png`;

const coreImage = `${imagePath}/Morgana_by_Gummies139.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "29px",
    right: "17px",
};

export default {
    previewImg: previewImage,
    rearMountsAreChassis: true,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Gummies139",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "2",
        "accessory1Value": "11",
        "accessory2Value": "8",
        "accessory3Value": "6",
        "accessory4Value": "2",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "MORGANA",
            foremostSrc: headMorganaImage,
        },
        {
            value: 1,
            label: "8 BALL",
            foremostSrc: head8BallImage,
        },
        {
            value: 2,
            label: "BLACKSPOT",
            foremostSrc: headBlackspotImage,
        },
        {
            value: 3,
            label: "CALIBAN",
            foremostSrc: headCalibanImage,
        },
        {
            value: 4,
            label: "CHEDDAH",
            foremostSrc: headCheddahImage,
        },
        {
            value: 5,
            label: "CHIMERA",
            foremostSrc: headChimeraImage,
        },
        {
            value: 6,
            label: "CYCLOPS",
            foremostSrc: headCyclopsImage,
        },
        {
            value: 7,
            label: "DOM",
            foremostSrc: headDomImage,
        },
        {
            value: 8,
            label: "DUNGAM",
            foremostSrc: headDungamImage,
        },
        {
            value: 9,
            label: "HOOD",
            foremostSrc: headHoodImage,
        },
        {
            value: 10,
            label: "HORNED HAIR",
            foremostSrc: headHornedHairImage,
        },
        {
            value: 11,
            label: "HORNED",
            foremostSrc: headHornedImage,
        },
        {
            value: 12,
            label: "KAZU",
            foremostSrc: headKazuImage,
        },
        {
            value: 13,
            label: "LABORER",
            foremostSrc: headLaborerImage,
        },
        {
            value: 14,
            label: "MAG",
            foremostSrc: headMagImage,
        },
        {
            value: 15,
            label: "MALICE",
            foremostSrc: headMaliceImage,
        },
        {
            value: 16,
            label: "MECHA 1",
            foremostSrc: headMecha1Image,
        },
        {
            value: 17,
            label: "MECHA 2",
            foremostSrc: headMecha2Image,
        },
        {
            value: 18,
            label: "MECHA 3",
            foremostSrc: headMecha3Image,
        },
        {
            value: 19,
            label: "MECHA 4",
            foremostSrc: headMecha4Image,
        },
        {
            value: 20,
            label: "MECHA 5",
            foremostSrc: headMecha5Image,
        },
        {
            value: 21,
            label: "MECHA 6",
            foremostSrc: headMecha6Image,
        },
        {
            value: 22,
            label: "MECHA 7",
            foremostSrc: headMecha7Image,
        },
        {
            value: 23,
            label: "MECHA 8",
            foremostSrc: headMecha8Image,
        },
        {
            value: 24,
            label: "NATURAL LAW",
            foremostSrc: headNaturalLawImage,
        },
        {
            value: 25,
            label: "NATURAL LAW HAIR",
            foremostSrc: headNaturalLawHairImage,
        },
        {
            value: 26,
            label: "NELSON",
            foremostSrc: headNelsonImage,
        },
        {
            value: 27,
            label: "NELSON FLIGHT TYPE",
            foremostSrc: headNelsonFlightTypeImage,
        },
        {
            value: 28,
            label: "NEMESIS",
            foremostSrc: headNemesisImage,
        },
        {
            value: 29,
            label: "RAIN TRANSFORMED",
            foremostSrc: headRainTransformedImage,
        },
        {
            value: 30,
            label: "RAIN HAIR",
            foremostSrc: headRainHairImage,
        },
        {
            value: 31,
            label: "SKULL",
            foremostSrc: headSkullImage,
        },
        {
            value: 32,
            label: "TOKUGAWA",
            foremostSrc: headTokugawaImage,
        },
        {
            value: 33,
            label: "VANQUISHER",
            foremostSrc: headVanquisherImage,
        },
        {
            value: 34,
            label: "VICEROY",
            foremostSrc: headViceroyImage,
        },
        {
            value: 35,
            label: "VISIONARY",
            foremostSrc: headVisionaryImage,
        },
        {
            value: 36,
            label: "VISIONARY 2",
            foremostSrc: headVisionary2Image,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "MORGANA",
            src: bodyMorganaImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by ",
                title: "Pysbomb",
                url: "https://qm-vox.tumblr.com/post/670936125146382336/field-guide-liminal-space",
            },
        },
        {
            value: 1,
            label: "MORGANA - 2",
            src: bodyMorgana2Image,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by ",
                title: "Pysbomb",
                url: "https://qm-vox.tumblr.com/post/670936125146382336/field-guide-liminal-space",
            },
        },
        {
            value: 2,
            label: "MORGANA - 12 ARMS",
            src: bodyMorgana12ArmsImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by ",
                title: "Pysbomb",
                url: "https://qm-vox.tumblr.com/post/670936125146382336/field-guide-liminal-space",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "COLLAR 1",
                src: collarCollar1Image,
            },
            {
                value: 1,
                label: "COLLAR 2",
                src: collarCollar2Image,
            },
            {
                value: 2,
                label: "COLLAR 6 ARMS",
                src: collarCollar6armsImage,
            },
            {
                value: 3,
                label: "COLLAR 12 ARMS",
                src: collarCollar12armsImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "6 ARMS - DEFAULT",
            src: armsOverDefaultImage,
        },
        {
            value: 1,
            label: "6 ARMS - QUAD SMGS",
            src: armsOverQuadSMGsImage,
        },
        {
            value: 2,
            label: "6 ARMS - 1 SMG",
            src: armsOverSMGImage,
        },
        {
            value: 3,
            label: "6 ARMS - BACK",
            backSrc: armsUnder6Image,
        },
        {
            value: 4,
            label: "12 ARMS",
            backSrc: armsOver12Image,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "6 ARMS",
            frontSrc: glowArms6Image,
        },
        {
            value: 1,
            label: "6 ARMS HACKING",
            frontSrc: glow6ArmsHackingImage,
        },
        {
            value: 2,
            label: "6 ARMS + SMG",
            frontSrc: glowArms6SMGImage,
        },
        {
            value: 3,
            label: "ARMS BACK",
            frontSrc: glowArmsUnderImage,
        },
        {
            value: 4,
            label: "ARMS ALL",
            frontSrc: glowArmsAllImage,
        },
        {
            value: 5,
            label: "HACKING",
            frontSrc: glowHackingImage,
        },
        {
            value: 6,
            label: "HACKING SIMPLE (SMG)",
            frontSrc: glowHackingSimpleImage,
        },
        {
            value: 7,
            label: "ALL ARMS HACKING",
            frontSrc: glowAllArmsHackingImage,
        },
        {
            value: 8,
            label: "MORGANA CHEST",
            src: glowMorganaChestImage,
        },
        {
            value: 9,
            label: "MORGANA CHEST 2",
            src: glowMorgana2Image,
        },
        {
            value: 10,
            label: "MORGANA CHEST ENERGY TRAILS",
            src: glowMorganaEnergyTrailsImage,
        },
        {
            value: 11,
            label: "MORGANA HEAD",
            foremostSrc: glowMorganaHeadImage,
        },
        {
            value: 12,
            label: "MORGANA HEAD + ENERGY TRAILS",
            foremostSrc: glowMorganaHeadImage,
            src: glowMorganaEnergyTrailsImage,
        },
        {
            value: 13,
            label: "PORTAL",
            backSrc: glowPortalImage,
            backmostSrc: glowPortalBlackImage,
        },
        {
            value: 14,
            label: "PORTAL + ASTEROIDS",
            backSrc: glowPortalAsteroidsImage,
            backmostSrc: glowPortalBlackImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "COLLAR",
            value: "weapon",
        },
        {
            label: "ARMS",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "EMPTY",
        "weapon": "COLLAR",
        "secondaryWeapon": "ARMS",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
