import BaseURL from "./images/Hosting-BaseURL";
import { shadowTypes } from "../styles/UnitShadow";

const imagePath = `${BaseURL}/Mechs/Custom/Ashgar-Orchis`;

// BODY
const bodyImage = `${imagePath}/Chassis.png`;

// HEADS
const headsUrsineImage = `${imagePath}/heads/Head - Ursine.png`;
const headsZHeadImage = `${imagePath}/heads/Head - Z Head.png`;

// WEAPONS
const weaponUrsineYangBladeImage = `${imagePath}/accessory/Accessory - Ursine Yang Blade.png`;
const weaponZBladeImage = `${imagePath}/accessory/Accessory - Z Blade.png`;

// ARMOR
const armorOverUrsineImage = `${imagePath}/armor/Armor Over - Ursine Armor.png`;
const armorUnderUrsineImage = `${imagePath}/armor/Armor Under - Ursine Armor.png`;
const armorOverZImage = `${imagePath}/armor/Armor Over - Z Armor.png`;
const armorUnderZImage = `${imagePath}/armor/Armor Under - Z Armor.png`;

// BACK MOUNT
const backCannonImage = `${imagePath}/rearmount/Back Mount - Cannon.png`;
const backDualGatlingsImage = `${imagePath}/rearmount/Back Mount - Dual Gatlings.png`;
const backEnergyCannonsImage = `${imagePath}/rearmount/Back Mount - Energy Cannons.png`;
const backUrsineShieldImage = `${imagePath}/rearmount/Back Mount - Ursine Shield.png`;
const backZArmorImage = `${imagePath}/rearmount/Back Mount - Z Armor.png`;

// ACCESSORIES
const accUrsineClawsImage = `${imagePath}/accessory/Back Mount - Ursine Claws.png`;
const accUrsineNeckBraidImage = `${imagePath}/accessory/Accessory - Ursine Neck Braid.png`;

// GLOWS
const glowEnergyCannonsImage = `${imagePath}/glows/Glow 1 - Energy Cannons.png`;
const glowUrsineAllBraidImage = `${imagePath}/glows/Glow 1 - Ursine All Braid.png`;
const glowUrsineAllImage = `${imagePath}/glows/Glow 1 - Ursine All.png`;
const glowUrsineArmorImage = `${imagePath}/glows/Glow 1 - Ursine Armor.png`;
const glowUrsineClawsImage = `${imagePath}/glows/Glow 1 - Ursine Claws.png`;
const glowUrsineShieldBraidImage = `${imagePath}/glows/Glow 1 - Ursine Shield Braid.png`;
const glowUrsineShieldImage = `${imagePath}/glows/Glow 1 - Ursine Shield.png`;
const glowZArmorImage = `${imagePath}/glows/Glow 1 - Z Armor.png`;
const glowClawsImage = `${imagePath}/glows/Glow 1- Claws.png`;
const glowUrsineNeckBraidImage = `${imagePath}/glows/Glow 2 - Ursine Neck Braid.png`;
const glowUrsineYangBladeImage = `${imagePath}/glows/Glow 2 - Ursine Yang Blade.png`;
const glowZBladeImage = `${imagePath}/glows/Glow 2 - Z Blade.png`;

const previewImage = `${imagePath}/preview.png`;
const restrictedPreviewImage = `${imagePath}/preview-restricted.png`;

const core1Image = `${imagePath}/toast_arctos_corebook_paintjob.png`;
const core2Image = `${imagePath}/Imperatrix-Arctos_Corebook.png`;

const shadowDetails = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "35px",
    right: "12px",
};

export default {
    previewImg: previewImage,
    restrictedPreviewImg: restrictedPreviewImage,
    isMegadeus: true,
    coreImg: [
        {
            src: core1Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "snipertoaster",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core2Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Imperatrix",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "1",
        "rearMountValue": "0",
        "weaponValue": "0",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "2",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "URSINE",
            src: headsUrsineImage,
        },
        {
            value: 1,
            label: "Z",
            src: headsZHeadImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "ORCHIS ARCTOS",
            src: bodyImage,
            shadowDetails,
            originalArtCredit: {
                prefixText: "Commissioned by Ashgar. Original by",
                title: "Gatsby",
                url: "https://bsky.app/profile/defiantdrills.bsky.social",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "CANNON",
                src: backCannonImage,
            },
            {
                value: 1,
                label: "DUAL GATLINGS",
                src: backDualGatlingsImage,
            },
            {
                value: 2,
                label: "ENERGY CANNONS",
                src: backEnergyCannonsImage,
            },
            {
                value: 3,
                label: "URSINE SHIELD",
                src: backUrsineShieldImage,
            },
            {
                value: 4,
                label: "Z ARMOR",
                src: backZArmorImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "URSINE YANG BLADE",
            frontSrc: weaponUrsineYangBladeImage,
        },
        {
            value: 1,
            label: "Z BLADE",
            frontSrc: weaponZBladeImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "URSINE",
            foremostSrc: armorOverUrsineImage,
            frontSrc: armorUnderUrsineImage,
        },
        {
            value: 1,
            label: "Z",
            foremostSrc: armorOverZImage,
            frontSrc: armorUnderZImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "CLAWS",
            frontSrc: glowClawsImage,
        },
        {
            value: 1,
            label: "ENERGY - CANNONS",
            frontSrc: glowEnergyCannonsImage,
        },
        {
            value: 2,
            label: "URSINE - ARMOR",
            frontSrc: glowUrsineArmorImage,
        },
        {
            value: 3,
            label: "URSINE - CLAWS",
            frontSrc: glowUrsineClawsImage,
        },
        {
            value: 4,
            label: "URSINE - SHIELD + BRAID",
            frontSrc: glowUrsineShieldBraidImage,
        },
        {
            value: 5,
            label: "URSINE - SHIELD",
            frontSrc: glowUrsineShieldImage,
        },
        {
            value: 6,
            label: "URSINE - NECK BRAID",
            frontSrc: glowUrsineNeckBraidImage,
        },
        {
            value: 7,
            label: "URSINE - YANG BLADE",
            frontSrc: glowUrsineYangBladeImage,
        },
        {
            value: 8,
            label: "URSINE - ALL + BRAID",
            frontSrc: glowUrsineAllBraidImage,
        },
        {
            value: 9,
            label: "URSINE - ALL",
            frontSrc: glowUrsineAllImage,
        },
        {
            value: 10,
            label: "Z BLADE",
            foremostSrc: glowZBladeImage,
        },
        {
            value: 11,
            label: "Z ARMOR",
            foremostSrc: glowZArmorImage,
        },
        {
            value: 12,
            label: "ACCESSORY - URSINE CLAWS",
            src: accUrsineClawsImage,
        },
        {
            value: 13,
            label: "ACCESSORY - URSINE NECK BRAID",
            frontSrc: accUrsineNeckBraidImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS / ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "ARMOR",
            value: "rearMount",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "BACK MOUNT",
            value: "weapon",
        },
        {
            label: "WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "ACCESSORY",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "ARMOR",
        "weapon": "BACK MOUNT",
        "secondaryWeapon": "WEAPON",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "ACCESSORY",
    },
};
