import nelson from "./Mechs-Nelson";
import nelson2 from "./Mechs-Nelson-2";
import blackbeard from "./Mechs-Blackbeard";
import bbeard from "./Mechs-BBeard";
import drake from "./Mechs-Drake";
import tortuga from "./Mechs-Tortuga";
import lancaster from "./Mechs-Lancaster";
import raleigh from "./Mechs-Raleigh";
import vlad from "./Mechs-Vlad";
import vlad2 from "./Mechs-Vlad-2";
import zheng from "./Mechs-Zheng";
import caliban from "./Mechs-Caliban";
import kidd from "./Mechs-Kidd";
import deployables from "./Mechs-Kidd-Deployables";
import raleigh2 from "./Mechs-Raleigh-2";
import Stortebeker from "./Mechs-Stortebeker";
import MechsEmpakaai from "./Mechs-Empakaai";
import MechsTaraxacum from "./Mechs-Taraxacum";

export default [
    Object.assign({ value: 0, label: "BLACKBEARD SUPERIOR" }, bbeard),
    Object.assign({ value: 1, label: "BLACKBEARD INFERIOR" }, blackbeard),
    Object.assign({ value: 2, label: "CALIBAN" }, caliban),
    Object.assign({ value: 3, label: "DRAKE" }, drake),
    Object.assign({ value: 4, label: "EMPAKAAI" }, MechsEmpakaai),
    Object.assign({ value: 5, label: "KIDD" }, kidd),
    Object.assign({ value: 6, label: "KIDD - DEPLOYABLES" }, deployables),
    Object.assign({ value: 7, label: "LANCASTER" }, lancaster),
    Object.assign({ value: 8, label: "NELSON" }, nelson),
    Object.assign({ value: 9, label: "NELSON 2.0" }, nelson2),
    Object.assign({ value: 10, label: "RALEIGH" }, raleigh),
    Object.assign({ value: 11, label: "RALEIGH 2.0" }, raleigh2),
    Object.assign({ value: 12, label: "STORTEBEKER" }, Stortebeker),
    Object.assign({ value: 13, label: "TARAXACUM" }, MechsTaraxacum),
    Object.assign({ value: 14, label: "TORTUGA" }, tortuga),
    Object.assign({ value: 15, label: "VLAD" }, vlad),
    Object.assign({ value: 16, label: "VLAD 2.0" }, vlad2),
    Object.assign({ value: 17, label: "ZHENG" }, zheng),
];
