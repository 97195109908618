import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/Vessel`;

// BODY
const bodyImage = `${imagePath}/VesselBody1.png`;

// HEADS
const headCoronaImage = `${imagePath}/heads/VesselCoronaHead.png`;
const headHarpyImage = `${imagePath}/heads/VesselHarpyHead.png`;
const headMarieCurieImage = `${imagePath}/heads/VesselMarieCurieHead.png`;

// WEAPONS
const weaponBladesImage = `${imagePath}/weapons/VesselBlades.png`;
const weaponGunHandsImage = `${imagePath}/weapons/VesselGunHands.png`;
const weaponHackingHandsImage = `${imagePath}/weapons/VesselHackingHands.png`;
const weaponHaymakerImage = `${imagePath}/weapons/Haymaker.png`;
const weaponPistolImage = `${imagePath}/weapons/VesselPistol.png`;
const weaponRevolverImage = `${imagePath}/weapons/VesselRevolver.png`;
const weaponVoidProjectImage = `${imagePath}/weapons/VesselVoidProject.png`;

// REAR MOUNT
const armor1Image = `${imagePath}/armor/VesselArmor1.png`;

// ACCESSORY
const accessoryExposeCoreImage = `${imagePath}/accessories/VesselExposeCore.png`;
const accessorySpines1Image = `${imagePath}/accessories/VesselSpines1.png`;

// GLOW
const glowAntennaGlintImage = `${imagePath}/glows/VesselAntennaGlint.png`;
const glowBladesGlowImage = `${imagePath}/glows/VesselBladesGlow.png`;
const glowCoreGlowImage = `${imagePath}/glows/VesselCoreGlow.png`;
const glowEncapsulateImage = `${imagePath}/glows/VesselEncapsulate.png`;
const glowExtrudeBladeImage = `${imagePath}/glows/VesselExtrudeBlade.png`;
const glowHijackerImage = `${imagePath}/glows/VesselHijacker.png`;
const glowSpinesGlow1Image = `${imagePath}/glows/VesselSpinesGlow1.png`;
const glowTGM1Image = `${imagePath}/glows/TGM1.png`;
const glowTwistingControlImage = `${imagePath}/glows/VesselTwistingControl.png`;
const glowVoidNekoImage = `${imagePath}/glows/VesselVoidNeko.png`;

const previewImage = `${imagePath}/preview.png`;

const core1Image = `${imagePath}/HorusHarpyCoreBook.png`;
const core2Image = `${imagePath}/CoronaCoreBook.png`;

const bipedShadow = {
    size: shadowTypes.SMALL,
    bottom: "55px",
    right: "11px",
};


export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: core1Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Dedlei",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core2Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Dedlei",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "0",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "7",
        "accessory2Value": "1",
        "accessory3Value": "10",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "CORONA",
            src: headCoronaImage,
        },
        {
            value: 1,
            label: "HARPY",
            src: headHarpyImage,
        },
        {
            value: 2,
            label: "MARIE CURIE",
            src: headMarieCurieImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "VESSEL",
            src: bodyImage,
            shadowDetails: bipedShadow,
            originalArtCredit: {
                prefixText: "Created by ",
                title: "DedLei",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "BLADES",
                src: weaponBladesImage,
            },
            {
                value: 1,
                label: "HACKING",
                src: weaponHackingHandsImage,
            },
            {
                value: 2,
                label: "HAYMAKER",
                src: weaponHaymakerImage,
            },
            {
                value: 3,
                label: "PISTOL",
                src: weaponPistolImage,
                backSrc: weaponGunHandsImage,
            },
            {
                value: 4,
                label: "REVOLVER",
                src: weaponRevolverImage,
                backSrc: weaponGunHandsImage,
            },
            {
                value: 5,
                label: "VOID PROJECT",
                src: weaponVoidProjectImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "SPINES 1",
            src: accessorySpines1Image,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "ARMOR",
            frontSrc: armor1Image,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "ANTENNA GLINT",
            frontSrc: glowAntennaGlintImage,
        },
        {
            value: 1,
            label: "BLADES GLOW",
            frontSrc: glowBladesGlowImage,
        },
        {
            value: 2,
            label: "CORE GLOW",
            frontSrc: glowCoreGlowImage,
        },
        {
            value: 3,
            label: "ENCAPSULATE",
            src: glowEncapsulateImage,
        },
        {
            value: 4,
            label: "EXTRUDE BLADE",
            frontSrc: glowExtrudeBladeImage,
        },
        {
            value: 5,
            label: "HIJACKER",
            frontSrc: glowHijackerImage,
        },
        {
            value: 6,
            label: "SPINES GLOW 1",
            frontSrc: glowSpinesGlow1Image,
        },
        {
            value: 7,
            label: "TGM 1",
            frontSrc: glowTGM1Image,
        },
        {
            value: 8,
            label: "TWISTING CONTROL",
            frontSrc: glowTwistingControlImage,
        },
        {
            value: 9,
            label: "VOID NEKO",
            frontSrc: glowVoidNekoImage,
        },
        {
            value: 10,
            label: "EXPOSE CORE",
            src: accessoryExposeCoreImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPONS",
            value: "weapon",
        },
        {
            label: "ACCESSORY",
            value: "secondaryWeapon",
        },
        {
            label: "ARMOR",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "ARMOR",
        "weapon": "WEAPONS",
        "secondaryWeapon": "ACCESSORY",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
